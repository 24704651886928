
import utils from "@/utils/utils";
import axios from "axios";
import { defineComponent } from "vue";
export default defineComponent({
	data() {
		return {
			startDate: "",
			endDate: "",
			studyId: null as string | null,
		};
	},

	mounted() {
		const start = this.$dayjs().weekday(-7);
		const end = this.$dayjs().weekday(-1);

		this.startDate = start.format("YYYY-MM-DD");
		this.endDate = end.format("YYYY-MM-DD");

		this.studyId = utils.sessionStudyId();

		if (this.studyId == null) {
			console.log("No study id provided");
			return;
		}
	},

	methods: {
		async downloadReport() {
			if (this.studyId == null) {
				console.log("No study Id provided=");
				return;
			}

			const url = `/api/v1/lumedi-connect-reports/studies/${this.studyId}/reconciliation?start=${this.startDate}&end=${this.endDate}`;

			await axios({
				url: url,
				method: "GET",
				responseType: "blob",
			})
				.then((response: any) => {
					const fileURL = window.URL.createObjectURL(new Blob([response.data]));
					const fileLink = document.createElement("a");

					fileLink.href = fileURL;
					fileLink.setAttribute("download", "Reconciliation Report.xlsx");
					document.body.appendChild(fileLink);

					fileLink.click();
				})
				.catch((error: any) => {
					this.$toast.add({
						severity: "error",
						summary: "Invalid start or end date",
						life: 3000,
					});
					console.log(error);
				});
		},
	},
});
