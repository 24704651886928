
// Vue
import { PatientSummaryResult, ReportsClient } from "@/api/LumediApi";
import utils from "@/utils/utils";
import { defineComponent } from "vue";

export default defineComponent({
	name: "adverse-events-ccglow-report",

	data: () => ({
		aeWithoutCcglowCount: 0,
		aeContainingCcglowCount: 0,
		showWithCcglowModal: false,
		showWithoutCcglowModal: false,
		patientsWithCcglow: [] as PatientSummaryResult[],
		patientsWithoutCcglow: [] as PatientSummaryResult[],
		studyId: "",
	}),

	async mounted() {
		this.studyId = utils.sessionStudyId();

		const reportClient = new ReportsClient();
		const summary = await reportClient.getCcglowSummary(this.studyId);

		this.aeWithoutCcglowCount = summary.aeWithoutCcglowCount;
		this.aeContainingCcglowCount = summary.aeContainingCcglowCount;
	},

	methods: {
		async displayWithCcglowDialog() {
			this.showWithCcglowModal = true;

			const reportsClient = new ReportsClient();
			this.patientsWithCcglow = await reportsClient.getPatientsWithAeContainingCcglow(
				this.studyId,
				true,
			);
		},

		async displayWithoutCcglowDialog() {
			this.showWithoutCcglowModal = true;

			if (this.studyId == null) {
				console.log("No study Id provided");
				return;
			}

			const reportsClient = new ReportsClient();
			this.patientsWithoutCcglow = await reportsClient.getPatientsWithAeContainingCcglow(
				this.studyId,
				false,
			);
		},
	},
});
